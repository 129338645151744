import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, toRaw, computed, toRefs, ref, onMounted } from 'vue';
import { selectJobByParam } from "/src/api/personnel";
import { selectExamineMonthlist, selectExamineRecordListByParams, insertClockInRecord, deleteExamineRecordByUserNo, selectExamineYearlist, selectExaminePersonlist } from "/src/api/attendance";
import moment from 'moment';
import useTablePagination from '@/hooks/pagination';
import getFieldRules from '@/utils/fieldRules';
const columnsMonth = [{
  title: '日期',
  dataIndex: 'date'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '身份证号',
  dataIndex: 'IDnum'
}, {
  title: '岗位',
  dataIndex: 'jobNo',
  slots: {
    customRender: 'jobNo'
  }
}, {
  title: '惩罚总次数',
  dataIndex: 'punishmentNum'
}, {
  title: '惩罚总金额',
  dataIndex: 'punishmentMoney'
}, {
  title: '奖励总次数',
  dataIndex: 'rewardNum'
}, {
  title: '奖励总金额',
  dataIndex: 'rewardMoney'
}, {
  title: '迟到次数',
  dataIndex: 'isLate'
}, {
  title: '早退次数',
  dataIndex: 'isEarly'
}, {
  title: '缺卡时段个数',
  dataIndex: 'halfDayCount'
}, {
  title: '旷工天数',
  dataIndex: 'absenteeism'
}, {
  title: '出勤天数',
  dataIndex: 'attendanceDays'
}, {
  title: '请假总时长(天)',
  dataIndex: 'totalTimeDay'
}, {
  title: '加班总时长(小时)',
  dataIndex: 'overTotalHours'
}, {
  title: '计加班费时长(小时)',
  dataIndex: 'overtime'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const yearsOptions = [{
  value: '2021',
  label: '2021'
}, {
  value: '2022',
  label: '2022'
}, {
  value: '2023',
  label: '2023'
}, {
  value: '2024',
  label: '2024'
}, {
  value: '2025',
  label: '2025'
}, {
  value: '2026',
  label: '2026'
}, {
  value: '2027',
  label: '2027'
}, {
  value: '2028',
  label: '2028'
}];
const columnsDetail = [{
  title: '事件时间',
  dataIndex: 'createTime'
}, {
  title: '姓名',
  dataIndex: 'userName'
}, {
  title: '类型',
  dataIndex: 'examintType',
  slots: {
    customRender: 'examintType'
  }
}, {
  title: '原因',
  dataIndex: 'comment',
  slots: {
    customRender: 'comment'
  }
}, {
  title: '金额',
  dataIndex: 'money',
  slots: {
    customRender: 'money'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  setup() {
    onMounted(() => {
      dateMonth.value = defaultSelectDate.monthDate;
      monthNow.value = format(defaultSelectDate.monthDate).slice(0, 7);
      selectExamineMonthlist1();
    });
    let activeKey = ref('1');
    let tableData = ref([]);
    let tableDataDetail = ref([]);
    let btnLoading = ref(false);
    const loading = ref(false);
    let detailVisible = ref(false);
    let addVisible = ref(false);
    let recordNow = ref({});
    const formRef = ref();
    const formStateAdd = reactive({
      userNo: null,
      createTime: null,
      examintType: '',
      comment: null,
      money: null
    });
    const {
      validatePass,
      checkPositiveNumber
    } = getFieldRules();
    const rules = {
      examintType: [{
        required: true,
        validator: validatePass,
        trigger: 'change'
      }],
      createTime: [{
        required: true,
        message: '请选择日期',
        trigger: 'change',
        type: 'object'
      }],
      comment: [{
        required: true,
        message: '请输入原因',
        trigger: 'blur'
      }],
      money: [{
        required: true,
        validator: checkPositiveNumber,
        trigger: 'blur'
      }]
    };

    const handleCancelAdd = () => {
      formRef.value.resetFields();
      addVisible.value = false;
      btnLoading.value = false;
    };

    const onSubmitAdd = () => {
      formRef.value.validate().then(() => {
        let addData = {
          userNo: formStateAdd.userNo,
          createTime: format(formStateAdd.createTime).slice(0, 10),
          examintType: formStateAdd.examintType,
          awardComment: formStateAdd.examintType == 1 ? formStateAdd.comment : null,
          awardMoney: formStateAdd.examintType == 1 ? formStateAdd.money : null,
          punishmentComment: formStateAdd.examintType == 2 ? formStateAdd.comment : null,
          punishmentMoney: formStateAdd.examintType == 2 ? formStateAdd.money : null
        };
        insertClockInRecord1(addData);
      }).catch(error => {
        console.log('error', error);
      });
    }; ///////////////


    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;

      if (activeKey.value == '1') {
        selectExamineMonthlist1();
      } else if (activeKey.value == '2') {
        selectExamineYearlist1();
      } else if (activeKey.value == '3') {
        selectExamineMonthlist1();
      }
    };

    const dateMonth = ref();
    const dateYears = ref(moment().year());

    const onSubmit = () => {
      if ((formState.IDNumber == '' || formState.IDNumber == null) && (formState.name == '' || formState.name == null)) {
        _message.error('请输入姓名或身份证号');

        return;
      }

      selectExamineMonthlist1();
    };

    const handleCancel = () => {
      detailVisible.value = false;
    };

    const formState = reactive({
      name: '',
      IDNumber: ''
    }); //设置默认日期

    const defaultSelectDate = {
      dayDate: moment().startOf('day').subtract(1, 'days'),
      monthDate: moment().startOf('month').subtract(0, 'days')
    }; //时间戳转化日期

    const monthNow = ref('');

    const format = shijianchuo => {
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
    };

    const add0 = m => {
      return m < 10 ? '0' + m : m;
    }; //选择日期


    const changeMonth = () => {
      current.value = 1;
      pageSize.value = 10;
      selectExamineMonthlist1();
    };

    const changeYear = () => {
      current.value = 1;
      pageSize.value = 10;
      selectExamineYearlist1();
    }; //切换标签页


    const changeTabs = () => {
      current.value = 1;
      pageSize.value = 10;
      total.value = 0;
      dateMonth.value = defaultSelectDate.monthDate;

      if (activeKey.value == '1') {
        selectExamineMonthlist1();
      } else if (activeKey.value == '2') {
        selectExamineYearlist1();
      } else if (activeKey.value == '3') {
        tableData.value = [];
        Object.assign(formState, {
          name: '',
          IDNumber: ''
        });
      }
    }; //获取考核月数据


    const selectExamineMonthlist1 = async () => {
      let param = {};

      if (activeKey.value == '3') {
        param = {
          userName: formState.name,
          idCard: formState.IDNumber,
          pageNumber: current.value ? current.value : 1,
          limit: pageSize.value ? pageSize.value : 10
        };
      } else {
        param = {
          createTime: format(dateMonth.value).slice(0, 7),
          pageNumber: current.value ? current.value : 1,
          limit: pageSize.value ? pageSize.value : 10
        };
      }

      loading.value = true;
      let res = await selectExamineMonthlist(param);

      if (res.code === 200) {
        loading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              date: item.createTime,
              name: item.userName,
              IDnum: item.idCard,
              jobNo: item.jobName,
              punishmentNum: item.punishmentTotalCount,
              punishmentMoney: item.punishmentTotalMoney,
              rewardNum: item.awardTotalCount,
              rewardMoney: item.awardTotalMoney,
              attendanceDays: item.workDays,
              isLate: item.lateDays,
              isEarly: item.earlyDays,
              absenteeism: item.absenteeismDay,
              halfDayCount: item.halfDayCount,
              overtime: item.overHours,
              totalTimeDay: item.totalTimeDay,
              overTotalHours: item.overTotalHours,
              userNo: item.userNo
            });
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //获取考核年数据


    const selectExamineYearlist1 = async () => {
      const param = {
        createTime: dateYears.value,
        pageNumber: current.value ? current.value : 1,
        limit: pageSize.value ? pageSize.value : 10
      };
      loading.value = true;
      let res = await selectExamineYearlist(param);

      if (res.code === 200) {
        loading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              date: item.createTime,
              name: item.userName,
              IDnum: item.idCard,
              jobNo: item.jobName,
              punishmentNum: item.punishmentTotalCount,
              punishmentMoney: item.punishmentTotalMoney,
              rewardNum: item.awardTotalCount,
              rewardMoney: item.awardTotalMoney,
              attendanceDays: item.workDays,
              isLate: item.lateDays,
              isEarly: item.earlyDays,
              absenteeism: item.absenteeismDay,
              halfDayCount: item.halfDayCount,
              overtime: item.overHours,
              totalTimeDay: item.totalTimeDay,
              overTotalHours: item.overTotalHours,
              userNo: item.userNo
            });
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //获取奖惩详情


    const getDetail = async record => {
      recordNow.value = record;
      let res = await selectExamineRecordListByParams({
        userNo: record.userNo,
        currTime: record.date
      });

      if (res.code === 200) {
        detailVisible.value = true;
        let list = res.data;
        tableDataDetail.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableDataDetail.value.push({
              key: index + 1,
              createTime: item.createTime,
              userName: item.userName,
              examintType: item.examintType,
              comment: item.examintType == 1 ? item.awardComment : item.punishmentComment,
              money: item.examintType == 1 ? item.awardMoney : item.punishmentMoney,
              id: item.id
            });
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //添加奖惩事项


    const addExamine = record => {
      addVisible.value = true;
      formStateAdd.userNo = record.userNo;
    }; //添加奖惩事项接口


    const insertClockInRecord1 = async val => {
      btnLoading.value = true;
      let res = await insertClockInRecord(val);

      if (res.code === 200) {
        btnLoading.value = false;

        _message.success(res.message);

        handleCancelAdd();
        selectExamineMonthlist1();
        Object.assign(formStateAdd, {
          userNo: null,
          createTime: null,
          examintType: '',
          comment: null,
          money: null
        });
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //删除奖惩事项


    const deleteExamineRecordByUserNo1 = async record => {
      let res = await deleteExamineRecordByUserNo({
        id: record.id
      });

      if (res.code === 200) {
        _message.success(res.message);

        getDetail(recordNow.value);
        selectExamineMonthlist1();
      } else {
        _message.error(res.message);
      }
    };

    return {
      detailVisible,
      tableData,
      columnsMonth,
      tableDataDetail,
      columnsDetail,
      formState,
      yearsOptions,
      onSubmit,
      activeKey,
      dateMonth,
      dateYears,
      defaultSelectDate,
      monthNow,
      onPageChange,
      loading,
      changeMonth,
      getDetail,
      btnLoading,
      paginationConfig,
      handleCancel,
      formRef,
      rules,
      handleCancelAdd,
      onSubmitAdd,
      addVisible,
      addExamine,
      formStateAdd,
      deleteExamineRecordByUserNo1,
      recordNow,
      changeTabs,
      changeYear
    };
  }

});